/*
 * @Author: 冯杰
 * @Date: 2021-08-11 09:30:30
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-09-09 21:14:11
 * 陈列任务
 */
import Form, { formCreate } from '@/found/components/form';
import request from '@/found/utils/request';

import Header from './components/header.vue';
import Table from './table';
// import BtnCode from './table/components/btn.vue';

formCreate.component('Header', Header);
formCreate.component('Table', Table);
// formCreate.component('BtnCode', BtnCode);

export default {
  extends: Form,
  components: {
    formCreate: formCreate.$form(),
  },
  data() {
    return {
      rule: [],
      buttons: {
        submit: false,
        close: false,
        submitAudit: false,
      },
      formFunctionCode: 'dealerFineRecords',
    };
  },
  methods: {

    setRule(item) {
      const val = item;
      if (val.field === 'table') {
        val.on = {
          ...val.on,
          newData: (data) => {
            console.log(data, 1111);
            const header = this.getRule('header');
            header.props = {
              ...header.props,
              searchFormData: data,
            };
          },
        };
      }
      return val;
    },

    formComplete() {
    },
  },
  watch: {},
};
