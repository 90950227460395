<script>
import TablePage from '@/found/components/table_page';

export default {
  name: 'detail_table',
  extends: TablePage,
  data() {
    return {};
  },
  props: {
    propsObj: Object,
  },
  methods: {

  },
  created() {
    this.params = { ...this.propsObj };
    this.getConfigList('detail_list');
  },
};
</script>
