var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "header-box" },
    [
      _c("div", [
        _c("span", { staticClass: "test" }, [_vm._v("罚款金额合计：")]),
        _c("span", { staticClass: "money" }, [_vm._v(_vm._s(_vm.totalNum))]),
      ]),
      _c("div", [
        _c("span", { staticClass: "test" }, [_vm._v("罚款池余额：")]),
        _c("span", { staticClass: "money" }, [
          _vm._v(_vm._s(_vm.balanceAmount)),
        ]),
      ]),
      _c(
        "div",
        {
          on: {
            click: function ($event) {
              return _vm.openModal()
            },
          },
        },
        [
          _c("span", { staticClass: "test" }, [_vm._v("罚款池转出金额：")]),
          _c("span", { staticClass: "money" }, [_vm._v(_vm._s(_vm.outAmount))]),
        ]
      ),
      _c(
        "Modal",
        {
          ref: "modalForm",
          attrs: { modalConfig: _vm.modalConfig },
          on: { onClose: _vm.closeModal },
        },
        [
          _c("DetailModal", { attrs: { propsObj: _vm.propsObj } }),
          _c(
            "div",
            { staticClass: "dialog-footer" },
            [
              _c(
                "el-button",
                {
                  attrs: { icon: "el-icon-close", type: "danger" },
                  on: { click: _vm.closeModal },
                },
                [_vm._v("关闭")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }