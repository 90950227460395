import Form from '../../../../../../components/form';
import request from '../../../../../../utils/request';
import BudgetList from '../components/budget_list.vue';

formCreate.component('BudgetList', BudgetList);
export default {
  extends: Form,
  components: {
    formCreate: formCreate.$form(),
  },
  data() {
    return {
      formFunctionCode: 'save_budget_settings',
    };
  },
  methods: {
    // 设置rule
    setRule(item) {
      const v = item;
      if (v.field === 'budgetCode') { // 选择存入预算
        v.props = {
          ...v.props,
          params: {
            functionCode: 'tpm_budget_list',
          },
          getValue: (field) => this.getFieldValue(field),
          title: v.title,
        };
      }
      return v;
    },
    // 表单渲染完成后的回调
    formComplete() {
      request.post('/tpm/tpmpunishmentwrit/queryPunishmentBudget').then((res) => {
        if (res.success) {
          this.setValue({ budgetCode: res.result });
        }
      });
      const budgetCode = this.getRule('budgetCode'); // 存入预算
      // 存入预算
      budgetCode.on.change = (e) => {
        if (e && e.length > 0) {
          this.setValue({
            budgetCode: e[0].feeBudgetCode,
          });
        } else {
          this.setValue({
            budgetCode: null,
          });
        }
      };
    },

    // 提交
    submit() {
      const formData = this.getFormData(); // 获取表单的值
      if (formData) {
        const url = '/tpm/tpmpunishmentwrit/updatePunishmentBudget';
        const params = { ...formData };
        request.post(url, params).then((res) => {
          if (res.success) {
            this.$message({
              type: 'success',
              message: '操作成功',
            });
            this.$emit('onClose');
            this.$emit('onGetList');
          }
        });
      }
    },
  },
};
