<script>
import TablePage from '@/found/components/table_page';
import request from '@/found/utils/request';
import Form from '../form';
import BudgetForm from '../budgetForm';

export default {
  name: 'dealer_fine_records_list',
  extends: TablePage,
  data() {
    return {
      formConfig: {},
      modalConfig: {
        title: '新增',
        visible: false,
        type: '',
      },
      searchFormData: {},
    };
  },
  components: {
    Form,
    BudgetForm,
  },
  methods: {
    /** @override 重写重置按钮 */
    beforeSearchEvent({ $event }) {
      this.$emit('newData', this.formData);
      if ($event.type === 'reset') {
        this.$emit('newData', {});
      }
      return true;
    },
    modalClick({ val, row }) {
      console.log(val.code);
      if (val.code === 'view') {
        this.formName = 'Form';
        this.modalConfig.title = '罚款详情';
        this.modalConfig.width = '80%';
        this.formConfig.row = row;
        this.formConfig.code = val.code;
        this.openDrawer();
      }
      if (val.code === 'save_budget_settings') {
        this.formName = 'BudgetForm';
        this.modalConfig.title = '罚款预算设置';
        this.modalConfig.width = '80%';
        this.formConfig.row = row;
        this.formConfig.code = val.code;
        this.openDrawer();
      }
    },
  },
  created() {
    this.getConfigList('dealer_fine_records_list');
  },
};
</script>
