import Form from '../../../../../../components/form';
import request from '../../../../../../utils/request';

export default {
  extends: Form,
  components: {},
  data() {
    return {
      formFunctionCode: 'dealer_fine_records_form',
    };
  },
  methods: {
    // 表单渲染完成后的回调
    formComplete() {
      if (this.formConfig.code === 'view') {
        request.post('/tpm/tpmpunishmentwrit/query', {
          id: this.formConfig.row.id,
        }).then((res) => {
          if (res.success) {
            this.setValue(res.result);
          }
        });
      }
    },
  },
};
