<!--
 * @Author: 冯杰
 * @Date: 2021-08-05 10:43:43
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-09-09 20:25:51
 * @FilePath: 头部看板
-->
<template>
    <div class="header-box">
        <div>
          <span class="test">罚款金额合计：</span>
          <span class="money">{{totalNum}}</span>
        </div>
        <div>
          <span class="test">罚款池余额：</span>
          <span class="money">{{balanceAmount}}</span>
        </div>
        <div @click="openModal()">
          <span class="test">罚款池转出金额：</span>
          <span class="money">{{outAmount}}</span>
        </div>
        <Modal :modalConfig="modalConfig" @onClose="closeModal" ref="modalForm">
          <DetailModal :propsObj="propsObj" />
          <div class="dialog-footer">
            <el-button icon="el-icon-close" type="danger" @click="closeModal"
              >关闭</el-button
            >
          </div>
        </Modal>
    </div>
</template>

<script>
import request from '@/found/utils/request';
import Modal from '@/found/components/modal';
import DetailModal from './detail_table.vue';

export default {
  data() {
    return {
      totalNum: 0, // 罚款金额合计
      balanceAmount: 0,
      outAmount: 0,
      // 弹框信息
      modalConfig: {
        type: 'Drawer',
        visible: false,
      },
      // 弹框数据
      propsObj: null,
      formName: 'Form',
    };
  },
  props: {
    searchFormData: Object,
  },
  components: {
    Modal,
    DetailModal,
  },
  watch: {
    // searchFormData(val) {
    //   this.getData(val);
    //   console.log(val, 123);
    // },
    searchFormData: {
      deep: true,
      immediate: true,
      handler(val) {
        console.log(val, 123);
        this.data = val;
        this.getData(val);
      },
    },
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData(params) {
      request
        .post('/tpm/tpmpunishmentwrit/sumAmount', params)
        .then((res) => {
          if (!res.success) return;
          this.totalNum = res.result.totalAmount || 0;
          this.balanceAmount = res.result.balanceAmount || 0;
          this.outAmount = res.result.outAmount || 0;
        });
    },
    // 打开抽屉
    openModal() {
      this.formName = 'DetailModal';
      this.modalConfig = {
        visible: true,
        type: 'Drawer',
        title: '明细',
        width: '80%',
      };
      this.propsObj = this.data;
    },
    // 关闭弹框
    closeModal() {
      this.modalConfig.visible = false;
    },
  },
};
</script>

<style lang="less" scoped>
.header-box{
    min-width: 1431px;
    width: 100%;
    height: 80px;
    display: flex;
    // justify-content: space-between;
    align-items: center;
    div{
        margin-right: 24px;
        width: 20%;
        height: 80px;
        font-family: PingFangSC-Regular;
        color: #333333;
        border-radius: 4px;
        background-image: linear-gradient(-45deg, #EEF6FF 0%, #FFFFFF 100%);
        box-shadow: 0px 0px 10px 0px rgba(14,93,195,0.1);
        span{
            display: block;
            font-size: 16px;
        }
        .test{
            height: 24px;
            line-height: 24px;
            margin: 0px 0 10px 20px;
        }
        .money{
            font-size: 50px;
            color: #0E5DC3;
            font-weight: 500;
            margin-right: 20px;
            margin-left: 20px;
            text-align: right;
        }
    }
}
</style>
